.cookie-box {
  position: fixed;
  bottom: 0;
  padding: 1em;
  margin: 0;
  min-height: 5vh;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 200;
  color: #fff;
  background-color: rgba(11, 11, 11, 0.7);
  opacity: 0;
  transform: translateY(100%);
  animation: showCookies 0.5s 0.5s forwards;
  z-index: 100;
}
.cookie-box .cookie-btn {
  margin-top: 0.2em;
  padding: 0.5em 1em;
  font-size: 1rem;
  color: #fff;
  text-transform: uppercase;
  border: none;
  border-radius: 10px;
  background-color: #d63a94;
  transition: background-color 0.3s;
}
.cookie-box .cookie-btn:hover {
  background-color: #7b1d54;
}

.hide {
  display: none;
}

@keyframes showCookies {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@media (min-width: 1020px) {
  .cookie-box {
    font-size: 2.2rem;
  }
  .cookie-box .cookie-btn {
    font-size: 1.5rem;
  }
}
@media (min-width: 1020px) and (orientation: landscape) {
  .cookie-box {
    font-size: 1.5rem;
  }
  .cookie-box .cookie-btn {
    font-size: 1rem;
  }
}
@media (min-width: 1800px) and (orientation: landscape) {
  .cookie-box {
    font-size: 2rem;
  }
  .cookie-box .cookie-btn {
    font-size: 1.3rem;
  }
}
@media (min-width: 2500px) and (orientation: landscape) {
  .cookie-box {
    font-size: 2.5rem;
  }
  .cookie-box .cookie-btn {
    font-size: 1.5rem;
  }
}
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
}

::selection {
  background-color: #d63a94;
  color: #fff;
}

::-webkit-scrollbar {
  width: 1.5rem;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(transparent, #d63a94);
  border-radius: 100vw;
  border: 0.25em solid #333;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(transparent, #b12777);
}

::-webkit-scrollbar-track {
  background: #333;
}

.navbar {
  min-height: 5em;
}
.navbar .navbar-toggler {
  border: none;
  outline: none;
  background-color: rgba(33, 33, 33, 0.5);
  padding: 0.5em;
  border-radius: 10px;
}
.navbar .navbar-toggler .navbar-toggler-icon .fa-bars {
  color: #fff;
  font-size: 2rem;
}
.navbar .offcanvas {
  background-color: #000;
}
.navbar .offcanvas .offcanvas-header .btn-close .fa-xmark {
  color: #fff;
  font-size: 2rem;
}
.navbar .offcanvas .offcanvas-body {
  margin-top: 5em;
}
.navbar .offcanvas .offcanvas-body .nav-item .nav-link {
  color: white;
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 0.2em;
}
.navbar .offcanvas .offcanvas-body .nav-item .nav-link:hover {
  color: #b12777;
}
.navbar .offcanvas .offcanvas-body .nav-item .nav-link:focus {
  box-shadow: none;
  color: #b12777;
}
.navbar .offcanvas .offcanvas-body .nav-item .dropdown-menu {
  border: none;
  box-shadow: 0 0 15px gray;
}
.navbar .offcanvas .offcanvas-body .nav-item .dropdown-menu .dropdown-item {
  font-size: 1.5rem;
  padding: 0.5em;
}
.navbar .offcanvas .offcanvas-body .nav-item .dropdown-menu .dropdown-item:hover {
  color: #fff;
  background-color: #b12777;
}
.navbar .offcanvas .offcanvas-body .nav-item .dropdown-menu .dropdown-item:focus {
  outline: none;
  color: #fff;
  background-color: #b12777;
}

@media (min-width: 768px) {
  .navbar .navbar-toggler {
    margin-top: 1em;
    padding: 1em 1.2em 1.2em 1em;
    border-radius: 13px;
  }
  .navbar .navbar-toggler .navbar-toggler-icon .fa-bars {
    font-size: 2.5rem;
  }
  .navbar .offcanvas .offcanvas-header .btn-close .fa-xmark {
    font-size: 2.5rem;
  }
  .navbar .offcanvas .offcanvas-body {
    margin-top: 7em;
  }
  .navbar .offcanvas .offcanvas-body .nav-item .nav-link {
    font-size: 3.5rem;
  }
  .navbar .offcanvas .offcanvas-body .nav-item .dropdown-menu .dropdown-item {
    font-size: 1.8rem;
  }
}
@media (min-width: 992px) {
  .navbar {
    min-height: 5em;
    background-color: rgba(33, 33, 33, 0.7);
    color: #fff;
  }
  .navbar .offcanvas .offcanvas-body {
    margin-top: 0;
  }
  .navbar .offcanvas .offcanvas-body .nav-item .nav-link {
    text-transform: none;
    color: white;
    font-size: 1.7rem;
    font-weight: 300;
    padding: 0 1em;
  }
  .navbar .offcanvas .offcanvas-body .nav-item .dropdown-menu .dropdown-item {
    font-size: 1.5rem;
    padding: 0.5em;
  }
}
@media (min-width: 2500px) and (orientation: landscape) {
  .navbar {
    min-height: 7em;
  }
  .navbar .offcanvas .offcanvas-body .nav-item .nav-link {
    font-size: 2rem;
  }
  .navbar .offcanvas .offcanvas-body .nav-item .dropdown-menu .dropdown-item {
    font-size: 1.8rem;
  }
}
.wrapper {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.wrapper img {
  width: 20rem;
  margin-bottom: 2em;
}
.wrapper h1 {
  font-size: 3rem;
  margin-bottom: 0.5em;
}
.wrapper p {
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0;
  padding: 0 2em;
}

@media (min-width: 768px) {
  .wrapper img {
    width: 35rem;
    margin-bottom: 4em;
  }
  .wrapper h1 {
    font-size: 4rem;
    margin-bottom: 0.5em;
  }
  .wrapper p {
    font-size: 2rem;
  }
}
@media (min-width: 992px) {
  .wrapper img {
    width: 45rem;
    margin-bottom: 6em;
  }
}
@media (min-width: 1020px) and (orientation: landscape) {
  .wrapper img {
    width: 30rem;
    margin-bottom: 4em;
  }
  .wrapper h1 {
    font-size: 4rem;
    margin-bottom: 0.5em;
  }
  .wrapper p {
    font-size: 2rem;
  }
}
@media (min-width: 1800px) and (orientation: landscape) {
  .wrapper img {
    width: 50rem;
  }
}
@media (min-width: 2500px) and (orientation: landscape) {
  .wrapper img {
    width: 60rem;
    margin-bottom: 6em;
  }
  .wrapper h1 {
    font-size: 5rem;
    margin-bottom: 0.5em;
  }
  .wrapper p {
    font-size: 3rem;
  }
}
footer {
  width: 100%;
  min-height: 10em;
  padding: 2em 1em;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
footer .left-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
}
footer .left-section img {
  width: 9em;
  padding-bottom: 0.5em;
}
footer .left-section .copyrights {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
}
footer .left-section .copyrights span {
  color: #d63a94;
  font-weight: 600;
}
footer .left-section .copyrights .date {
  color: #fff;
  font-weight: 500;
}
footer .left-section .createdby {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 200;
}
footer .left-section .createdby a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
  font-weight: 400;
}
footer .left-section .createdby a:hover,
footer .left-section .createdby a:focus {
  outline: none;
  color: #d63a94;
}
footer .left-section .createdby a:visited {
  color: #fff;
  text-decoration: none;
}
footer .right-section {
  width: 100%;
  padding: 2em 0 0 0;
  display: flex;
  flex-direction: column;
  color: #fff;
  text-align: center;
}
footer .right-section h4 {
  margin: 0;
  padding-bottom: 0.5em;
  font-size: 1.3rem;
}
footer .right-section p {
  margin: 0;
  font-size: 1.3rem;
  font-weight: 200;
}
footer .right-section .regulations {
  padding-top: 1em;
  font-size: 1.3rem;
}
footer .right-section .regulations a {
  text-decoration: none;
  color: #fff;
  font-weight: 200;
}
footer .right-section .regulations a:hover {
  font-weight: 400;
}
footer .right-section .regulations a:visited {
  text-decoration: none;
  color: #fff;
}

@media (min-width: 768px) {
  footer {
    justify-content: space-around;
    flex-direction: row;
  }
  footer .left-section {
    width: 50%;
  }
  footer .left-section img {
    width: 12em;
    padding-bottom: 1em;
  }
  footer .right-section {
    width: 50%;
    padding: 2em 0 2em 0;
  }
}
@media (min-width: 1020px) and (orientation: landscape) {
  footer {
    padding: 2em 20em;
  }
  footer .left-section img {
    width: 15em;
  }
  footer .left-section .copyrights {
    font-size: 1.8rem;
  }
  footer .left-section .createdby {
    font-size: 1.4rem;
  }
  footer .right-section h4 {
    font-size: 1.5rem;
  }
  footer .right-section p {
    font-size: 1.5rem;
  }
  footer .right-section .regulations {
    font-size: 1.5rem;
  }
}
@media (min-width: 1800px) and (orientation: landscape) {
  footer {
    padding: 2em 50em;
  }
  footer .left-section img {
    width: 10em;
  }
  footer .left-section .copyrights {
    font-size: 1.5rem;
  }
  footer .left-section .createdby {
    font-size: 1.2rem;
  }
  footer .right-section h4 {
    font-size: 1.5rem;
  }
  footer .right-section p {
    font-size: 1.5rem;
  }
  footer .right-section .regulations {
    font-size: 1.3rem;
  }
}
@media (min-width: 2500px) and (orientation: landscape) {
  footer {
    padding: 2em 80em;
  }
  footer .left-section img {
    width: 13em;
  }
  footer .left-section .copyrights {
    font-size: 1.8rem;
  }
  footer .left-section .createdby {
    font-size: 1.4rem;
  }
  footer .right-section h4 {
    font-size: 1.7rem;
  }
  footer .right-section p {
    font-size: 1.7rem;
  }
  footer .right-section .regulations {
    font-size: 1.4rem;
  }
}