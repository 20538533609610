@use 'colors' as c;
@import '_cookieAlert';

*,
*::before,
*::after {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
html {
	font-size: 62.5%;
	scroll-behavior: smooth;
}
body {
	font-family: 'Poppins', sans-serif;
}
::selection {
	background-color: #d63a94;
	color: #fff;
}

//suwak scroll start Chrome, Edge, Safari
::-webkit-scrollbar {
	width: 1.5rem;
}
::-webkit-scrollbar-thumb {
	background: linear-gradient(transparent, c.$pink);
	border-radius: 100vw;
	border: 0.25em solid #333;
}
::-webkit-scrollbar-thumb:hover {
	background: linear-gradient(transparent, c.$pinkHover);
}

::-webkit-scrollbar-track {
	background: #333;
}
.navbar {
	min-height: 5em;
	.navbar-toggler {
		border: none;
		outline: none;
		background-color: rgba(33, 33, 33, 0.5); //
		padding: 0.5em; //
		border-radius: 10px; //
		.navbar-toggler-icon {
			.fa-bars {
				color: #fff;
				font-size: 2rem;
			}
		}
	}
	.offcanvas {
		background-color: #000;
		.offcanvas-header {
			.btn-close {
				.fa-xmark {
					color: #fff;
					font-size: 2rem;
				}
			}
		}
		.offcanvas-body {
			margin-top: 5em;
			.nav-item {
				.nav-link {
					color: white;
					font-size: 3rem;
					text-transform: uppercase;
					font-weight: 700;
					padding: 0 0.2em;
				}
				.nav-link:hover {
					color: c.$pinkHover;
				}
				.nav-link:focus {
					box-shadow: none;
					color: c.$pinkHover;
				}
				.dropdown-menu {
					border: none;
					box-shadow: 0 0 15px gray;
					.dropdown-item {
						font-size: 1.5rem;
						padding: 0.5em;
					}
					.dropdown-item:hover {
						color: #fff;
						background-color: c.$pinkHover;
					}
					.dropdown-item:focus {
						outline: none;
						color: #fff;
						background-color: c.$pinkHover;
					}
				}
			}
		}
	}
}

// =========================
//			MEDIA
// =========================

@media (min-width: 768px) {
	.navbar {
		.navbar-toggler {
			margin-top: 1em; //
			padding: 1em 1.2em 1.2em 1em; //
			border-radius: 13px; //
			.navbar-toggler-icon {
				.fa-bars {
					font-size: 2.5rem; //
				}
			}
		}
		.offcanvas {
			.offcanvas-header {
				.btn-close {
					.fa-xmark {
						font-size: 2.5rem; //
					}
				}
			}
			.offcanvas-body {
				margin-top: 7em; //
				.nav-item {
					.nav-link {
						font-size: 3.5rem; //
					}
					.dropdown-menu {
						.dropdown-item {
							font-size: 1.8rem; //
						}
					}
				}
			}
		}
	}
}
@media (min-width: 992px) {
	.navbar {
		min-height: 5em;
		background-color: rgba(33, 33, 33, 0.7);
		color: #fff;
		.offcanvas {
			.offcanvas-body {
				margin-top: 0;
				.nav-item {
					.nav-link {
						text-transform: none;
						color: white;
						font-size: 1.7rem;
						font-weight: 300;
						padding: 0 1em;
					}
					.dropdown-menu {
						.dropdown-item {
							font-size: 1.5rem;
							padding: 0.5em;
						}
					}
				}
			}
		}
	}
}
@media (min-width: 2500px) and (orientation: landscape) {
	.navbar {
		min-height: 7em;
		.offcanvas {
			.offcanvas-body {
				.nav-item {
					.nav-link {
						font-size: 2rem;
					}
					.dropdown-menu {
						.dropdown-item {
							font-size: 1.8rem;
						}
					}
				}
			}
		}
	}
}

.wrapper {
	min-height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	img {
		width: 20rem;
		margin-bottom: 2em;
	}
	h1 {
		font-size: 3rem;
		margin-bottom: 0.5em;
	}
	p {
		font-size: 1.5rem;
		font-weight: 300;
		margin: 0;
		padding: 0 2em;
	}
}

// =========================
//			MEDIA
// =========================

@media (min-width: 768px) {
	.wrapper {
		img {
			width: 35rem;
			margin-bottom: 4em;
		}
		h1 {
			font-size: 4rem;
			margin-bottom: 0.5em;
		}
		p {
			font-size: 2rem;
		}
	}
}

@media (min-width: 992px) {
	.wrapper {
		img {
			width: 45rem;
			margin-bottom: 6em;
		}
	}
}

@media (min-width: 1020px) and (orientation: landscape) {
	.wrapper {
		img {
			width: 30rem;
			margin-bottom: 4em;
		}
		h1 {
			font-size: 4rem;
			margin-bottom: 0.5em;
		}
		p {
			font-size: 2rem;
		}
	}
}

@media (min-width: 1800px) and (orientation: landscape) {
	.wrapper {
		img {
			width: 50rem;
		}
	}
}

@media (min-width: 2500px) and (orientation: landscape) {
	.wrapper {
		img {
			width: 60rem;
			margin-bottom: 6em;
		}
		h1 {
			font-size: 5rem;
			margin-bottom: 0.5em;
		}
		p {
			font-size: 3rem;
		}
	}
}

footer {
	width: 100%;
	min-height: 10em;
	padding: 2em 1em;
	background-color: #333;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	.left-section {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		color: #fff;
		img {
			width: 9em;
			padding-bottom: 0.5em;
		}
		.copyrights {
			margin: 0;
			font-size: 1.5rem;
			font-weight: 500;
			text-align: center;
			span {
				color: c.$pink;
				font-weight: 600;
			}
			.date {
				color: #fff;
				font-weight: 500;
			}
		}
		.createdby {
			margin: 0;
			font-size: 1.2rem;
			font-weight: 200;
			a {
				color: #fff;
				text-decoration: none;
				transition: color 0.3s;
				font-weight: 400;
			}
			a:hover,
			a:focus {
				outline: none;
				color: c.$pink;
			}
			a:visited {
				color: #fff;
				text-decoration: none;
			}
		}
	}
	.right-section {
		width: 100%;
		padding: 2em 0 0 0;
		display: flex;
		flex-direction: column;
		color: #fff;
		text-align: center;
		h4 {
			margin: 0;
			padding-bottom: 0.5em;
			font-size: 1.3rem;
		}
		p {
			margin: 0;
			font-size: 1.3rem;
			font-weight: 200;
		}
		.regulations {
			padding-top: 1em;
			font-size: 1.3rem;
			a {
				text-decoration: none;
				color: #fff;
				font-weight: 200;
			}
			a:hover {
				font-weight: 400;
			}
			a:visited {
				text-decoration: none;
				color: #fff;
			}
		}
	}
}

// =========================
//			MEDIA
// =========================

@media (min-width: 768px) {
	footer {
		justify-content: space-around; //
		flex-direction: row; //
		.left-section {
			width: 50%; //
			img {
				width: 12em; //
				padding-bottom: 1em; //
			}
			// .copyrights {
			// 	font-size: 1.5rem;
			// }
			// .createdby {
			// 	font-size: 1.2rem;
			// }
		}
		.right-section {
			width: 50%; //
			padding: 2em 0 2em 0; //
			// h4 {
			// 	padding-bottom: .5em;
			// 	font-size: 1.3rem;
			// }
			// p {
			// 	font-size: 1.3rem;
			// }
			// .regulations {
			// 	padding-top: 1em;
			// 	font-size: 1.3rem;
			// }
		}
	}
}
@media (min-width: 1020px) and (orientation: landscape) {
	footer {
		padding: 2em 20em;
		.left-section {
			img {
				width: 15em; //
			}
			.copyrights {
				font-size: 1.8rem;
			}
			.createdby {
				font-size: 1.4rem;
			}
		}
		.right-section {
			h4 {
				font-size: 1.5rem;
			}
			p {
				font-size: 1.5rem;
			}
			.regulations {
				font-size: 1.5rem;
			}
		}
	}
}
@media (min-width: 1800px) and (orientation: landscape) {
	footer {
		padding: 2em 50em;
		.left-section {
			img {
				width: 10em; //
			}
			.copyrights {
				font-size: 1.5rem; //
			}
			.createdby {
				font-size: 1.2rem; //
			}
		}
		.right-section {
			h4 {
				font-size: 1.5rem;
			}
			p {
				font-size: 1.5rem;
			}
			.regulations {
				font-size: 1.3rem;
			}
		}
	}
}
@media (min-width: 2500px) and (orientation: landscape) {
	footer {
		padding: 2em 80em;
		.left-section {
			img {
				width: 13em; //
			}
			.copyrights {
				font-size: 1.8rem;
			}
			.createdby {
				font-size: 1.4rem;
			}
		}
		.right-section {
			h4 {
				font-size: 1.7rem;
			}
			p {
				font-size: 1.7rem;
			}
			.regulations {
				font-size: 1.4rem;
			}
		}
	}
}
